import { CommonModule } from '@angular/common';
import { Component, computed, HostListener } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from './services/config.service';
import { Observable } from 'rxjs';
import { TidioButtonComponent } from './components/commons/tidio-button/tidio-button.component';
import { ToastComponent } from './components/ui/toast/toast.component';
import { StoreSettingService } from './services/store-setting.service';
import { RouterLoaderComponent } from './components/commons/layout/router-loader/router-loader.component';
import { RoutesService } from './services/routes.service';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './components/ui/modal/modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TidioButtonComponent,
    ToastComponent,
    RouterLoaderComponent,
    RouterModule,
    ModalComponent,
  ],
})
export class AppComponent {
  config$: Observable<any> = this.configService.init();
  selectAsideRight$: Observable<boolean> = this.settingService.selectAsideRight();
  selectAsideLeft$: Observable<boolean> = this.settingService.selectAsideLeft();
  tidioAvailable = computed(
    () =>
      this.routesService.route() == '/' ||
      this.routesService.route() == '/app' ||
      this.routesService.route() == '/casino',
  );
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const innerWidth = window.innerWidth;
    this.configService.displayWidth.set(innerWidth);
    document.documentElement.style.setProperty('--display-w', `${innerWidth}px`);
  }
  constructor(
    private configService: ConfigService,
    private settingService: StoreSettingService,
    private routesService: RoutesService,
  ) {
    setInterval(() => this.onResize(), 1000);
  }
}
