import { toastReducer } from './toast/reducers';
import { settingsReducer } from './settings/reducers';
import { authReducer } from './auth/reducers';
import { resourcesReducer } from './resources/reducers';
import { modalReducer } from './modal/reducers';
import { bettingReducer } from './betting/reducers';
import { walletReducer } from './wallet/reducers';
import { sessionChatReducer } from './chat/reducers';
import { notificationsReducer } from './notifications/reducers';
import { bookmarksReducer } from './bookmarks/reducers';
import { IToast } from './toast/model';
import { ISettings } from './settings/model';
import { searchReducer } from './searcher/reducers';
import { sessionGameReducer } from './game/reducers';
export interface IAppState {
  toasts: IToast[];
  settings: ISettings;
}
export const reducers = {
  toasts: toastReducer,
  modal: modalReducer,
  settings: settingsReducer,
  auth: authReducer,
  resources: resourcesReducer,
  betting: bettingReducer,
  wallet: walletReducer,
  chat: sessionChatReducer,
  notif: notificationsReducer,
  bookmarks: bookmarksReducer,
  searcher: searchReducer,
  games: sessionGameReducer,
};
