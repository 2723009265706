import { createReducer, on } from '@ngrx/store';
import { assign } from 'lodash';
import { addIcon } from './actions';
import { IResources } from './model';
const initialState: Readonly<IResources> = {
  server_GMT_offset: 0,
  settings: null,
  bonus: [],
  icons: {},
  geoip: null,
};
export const resourcesReducer = createReducer(
  initialState,
  on(addIcon, (state, { name, data }) => assign({}, state, { icons: assign({}, state.icons, { [name]: data }) })),
);
