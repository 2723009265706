import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { delay, Observable, of } from 'rxjs';
import { DepositsFiatService } from '@app/services/wallet/deposits.fiat.service';

@Injectable({
  providedIn: 'root',
})
export class PendingDepositsResolver implements Resolve<boolean> {
  constructor(private depositsFiatService: DepositsFiatService) {}

  resolve(): Observable<boolean> {
    this.depositsFiatService.loadPendingDeposits();
    return of(true).pipe(delay(1000));
  }
}
