import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { ResourcesService } from '../services/resources.service';
import { BonusService } from '@app/services/wallet/bonus.service';

@Injectable({
  providedIn: 'root',
})
export class AccountResolver implements Resolve<boolean> {
  constructor(
    private resourcesService: ResourcesService,
    private bonusService: BonusService,
  ) {}

  resolve(): Observable<boolean> {
    return this.resourcesService.bicho().pipe(
      switchMap(() => this.bonusService.basePromos()),
      map(() => true),
    );
  }
}
