import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DrawService } from '@app/services/bicho/draw.service';

@Injectable({
  providedIn: 'root',
})
export class DrawResolver implements Resolve<any> {
  constructor(private drawService: DrawService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const drawId = route.paramMap.get('draw_id');
    if (!drawId) {
      throw new Error('Draw ID is required');
    }
    return this.drawService._expand(drawId);
  }
}
