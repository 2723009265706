@if (config$ | async) {
  <div class="wrapper">
    <router-outlet></router-outlet>
    @if ((selectAsideRight$ | async) || (selectAsideLeft$ | async)) {
    } @else {
      @if (tidioAvailable()) {
        <app-tidio-button />
      }
    }
  </div>
}
<div class="toast">
  <app-toast />
</div>
<div class="modal">
  <app-modal />
</div>
<app-router-loader />
