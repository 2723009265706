import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectToasts } from '@app/store/toast/selectors';
import { removeToast, setShowToast } from '@app/store/toast/actions';
import { selectModalState } from '@app/store/modal/selectors';
import { removeModal } from '@app/store/modal/actions';

@Injectable({
  providedIn: 'root',
})
export class ToastModalsService {
  constructor(private store: Store) {}

  selectToasts() {
    return this.store.select(selectToasts);
  }
  setShowToast(id: number, show: boolean): void {
    this.store.dispatch(setShowToast({ id, show }));
  }
  removeToast(id: number): void {
    this.store.dispatch(removeToast({ id }));
  }
  selectModalState() {
    return this.store.select(selectModalState);
  }
  removeModal(): void {
    this.store.dispatch(removeModal());
  }
}
