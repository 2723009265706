import { createSelector, createFeatureSelector } from '@ngrx/store';

import { IToast } from './model'

export const selectToastsFeat = createFeatureSelector<ReadonlyArray<IToast>>('toasts');

export const selectToasts = createSelector(
    selectToastsFeat,
    (toasts) => {
        return toasts;
    }
);