import { createReducer, on } from '@ngrx/store';
import { assign, remove } from 'lodash';
import {
  addGamesEndless,
  addGamesToCatProv,
  gamesEndlessLoading,
  setFiltersCategory,
  setFiltersProvider,
  setFiltersWord,
  setGameSearcherDevice,
  setGames,
  setLoading,
  setSearcherShow,
  setTree,
  toggleCat,
  toggleProvider,
} from './actions';
import { ISearch } from './model';
import { IGame } from '@libs/interfaces';

const initialState: Readonly<ISearch> = {
  list: [],
  filters: {
    provider: undefined,
    category: undefined,
    word: '',
    device: 'mobile',
  },
  show: false,
  loading: false,
  endlessGames: [],
  endlessGamesLoading: 0,
  tree: [],
};
export const searchReducer = createReducer(
  initialState,
  on(setGames, (state, { games }) => assign({}, state, { list: games })),
  on(setSearcherShow, (state, { show }) => assign({}, state, { show: show })),
  on(setLoading, (state, { loading }) => assign({}, state, { loading: loading })),
  on(setFiltersWord, (state, { word }) => assign({}, state, { filters: assign({}, state.filters, { word: word }) })),
  on(setFiltersProvider, (state, { provider }) =>
    assign({}, state, { filters: assign({}, state.filters, { provider: provider }) }),
  ),
  on(setFiltersCategory, (state, { category }) =>
    assign({}, state, { filters: assign({}, state.filters, { category: category }) }),
  ),
  on(gamesEndlessLoading, state => assign({}, state, { gamesEndlessLoading: state.endlessGamesLoading + 1 })),
  on(addGamesEndless, (state, { games }) => {
    remove(games, (g: IGame) => state.endlessGames.find((gg: IGame) => g.external_id == gg.external_id));
    return assign({}, state, { endlessGames: [...state.endlessGames, ...games] });
  }),
  on(setGameSearcherDevice, (state, { device }) =>
    assign({}, state, { filters: assign({}, state.filters, { device: device }) }),
  ),
  on(setTree, (state, { tree }) => assign({}, state, { tree: tree })),
  on(toggleCat, (state, { cat }) =>
    assign({}, state, { tree: state.tree.map(c => (c === cat ? { ...c, open: !c.open } : c)) }),
  ),
  on(toggleProvider, (state, { provider }) =>
    assign({}, state, {
      tree: state.tree.map(c => {
        if (c.providers) {
          return {
            ...c,
            providers: c.providers.map(p => (p === provider ? { ...p, open: !p.open } : p)),
          };
        }
        return c;
      }),
    }),
  ),
  on(addGamesToCatProv, (state, { cat, prov, games }) => {
    const updatedTree = state.tree.map(c => {
      if (c.category === cat) {
        return {
          ...c,
          providers: c.providers.map(p => {
            if (p.provider === prov) {
              return {
                ...p,
                games: games,
              };
            }
            return p;
          }),
        };
      }
      return c;
    });
    return assign({}, state, { tree: updatedTree });
  }),
);
