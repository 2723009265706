import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ResourcesService } from '../services/resources.service';

@Injectable({
  providedIn: 'root',
})
export class CasinoResolver implements Resolve<boolean> {
  constructor(private resourcesService: ResourcesService) {}

  resolve(): Observable<boolean> {
    return this.resourcesService.casino();
  }
}
