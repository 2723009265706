import {
  ApplicationConfig,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
  isDevMode,
} from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { appRoutes } from './app.routes';
import { TranslateModule } from '@ngx-translate/core';
import { StoreDevModules } from './devTools/test';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { ThemeService } from './services/theme.service';
import { AuthService } from './services/auth.service';
import { LocalstorageService } from './services/localstorage.service';
import { UserService } from './services/user.service';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { AwakeService } from './services/awake.service';
import { LangService } from './services/lang.service';
import { ApiService } from './services/api.service';
import { reducers } from '@app/store/landing.state';
import { AuthInterceptor } from './interceptors/client.auth.interceptor';
import { authInterceptor } from './interceptors/auth.functional.interceptor';
import { UpdateService } from './services/app.updates.service';
import { provideServiceWorker } from '@angular/service-worker';
import { RoutesService } from './services/routes.service';
import { DeviceService } from './services/device.service';
import { ConfigService } from './services/config.service';
import { BettingEffects } from '@app/store/betting/effects';
import { EffectsModule } from '@ngrx/effects';
import { TranstalerLoader } from './helper/translater.helper';
import { ScheduleService } from './services/bicho/schedule-selector.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { GamesEffects } from './store/game/effects';
import { StoreSettingService } from './services/store-setting.service';
import { BonusService } from './services/wallet/bonus.service';
import { WalletEffects } from './store/wallet/effects';
import { CasinoConfigService } from './services/casino/casino-config.service';
import { RefererService } from './services/referer.service';
export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(TranslateModule.forRoot(TranstalerLoader)),
    importProvidersFrom(EffectsModule.forRoot(BettingEffects, GamesEffects, WalletEffects)),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([authInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    provideRouter(appRoutes, withViewTransitions()),
    provideStore(reducers),
    provideEnvironmentNgxMask(),
    StoreDevModules,
    ThemeService,
    DeviceService,
    ApiService,
    AuthService,
    LocalstorageService,
    UserService,
    LangService,
    AwakeService,
    UpdateService,
    RoutesService,
    ConfigService,
    ScheduleService,
    StoreSettingService,
    BonusService,
    CasinoConfigService,
    RefererService,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
