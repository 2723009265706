import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { IGameActivity } from '@libs/interfaces';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';
import { GameSearcherService } from '@app/services/casino/casino-searcher.service';
@Injectable()
export class GamesEffects {
  activity;
  constructor(
    private actions: Actions,
    private service: GameSearcherService,
  ) {
    this.activity = createEffect(() =>
      this.actions.pipe(
        ofType('[Game Session] on start app load activities'),
        exhaustMap(() =>
          this.service.fetchGamesActivity().pipe(
            map((activity: IGameActivity[]) => ({ type: '[Game Session] Add games activities', activity: activity })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
  }
}
