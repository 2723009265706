import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { IBet, IDraw, IEthBlock } from '@libs/interfaces';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { DrawService } from '@app/services/bicho/draw.service';
@Injectable()
export class BettingEffects {
  ethBlocks;
  activity;
  nextDraws;
  constructor(
    private actions: Actions,
    private service: DrawService,
  ) {
    this.ethBlocks = createEffect(() =>
      this.actions.pipe(
        ofType('[Betting] fetch eth blocks'),
        exhaustMap(() =>
          this.service.fetchEthBlocks().pipe(
            map((blocks: IEthBlock[]) => ({ type: '[Betting] Add eth blocks', blocks })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
    this.activity = createEffect(() =>
      this.actions.pipe(
        ofType('[Betting] on start app'),
        exhaustMap(() =>
          this.service.fetchLastbetting().pipe(
            map((bets: IBet[]) => ({ type: '[Betting] Add last betting', bets })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
    this.nextDraws = createEffect(() =>
      this.actions.pipe(
        ofType('[Betting] fetch next draws'),
        exhaustMap(() =>
          this.service.fetchUserNextDraws().pipe(
            map((draws: IDraw[]) => ({ type: '[Betting] Add next Draws', draws })),
            catchError(() => EMPTY),
          ),
        ),
      ),
    );
  }
}
