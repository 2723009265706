import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataResolver implements Resolve<boolean> {
  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  resolve(): Observable<boolean> {
    const canActivate = this.userService.stateData() > 0;
    return canActivate ? of(true) : this.toast();
  }
  toast() {
    this.userService.msgDocsCanntActivate();
    this.router.navigateByUrl('/account/me');
    return of(false);
  }
}
